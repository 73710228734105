.checkout-steps {
  $borderBottomWidth: 2px;
  $root: &;
  position: relative;
  margin: $checkoutStepsMobileMargin;
  font-size: 13px;

  @media (min-width: $screen-tablet-portrait) {
    font-size: 18px;
    margin: $checkoutStepsDesktopMargin;
  }

  &__track {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    max-width: $checkoutStepsMobileMaxWidth;
    margin: auto;

    @media (min-width: $screen-tablet-portrait) {
      max-width: $checkoutStepsDesktopMaxWidth;
      margin: auto;
    }
  }

  &__step {
    position: relative;
    text-align: center;
    cursor: default;
    text-decoration: none;
    top: $borderBottomWidth;
    min-width: $checkoutStepsStepMinWidth;

    &::before {
      position: relative;
      content: attr(data-step);
      display: inline-block;
      border-radius: 50%;
      z-index: 1;
      width: $checkoutStepsMobileWidth;
      height: $checkoutStepsMobileWidth;
      background: $checkoutStepsColor;
      color: $checkoutStepsTextColor;
      line-height: $checkoutStepsMobileLineHeight;
      font-size: $checkoutStepsMobileFontSize;
      font-weight: $checkoutStepsFontWeight;

      @media (min-width: $screen-tablet-portrait) {
        font-size: $checkoutStepsDesktopFontSize;
        line-height: $checkoutStepsDesktopLineHeight;
        width: $checkoutStepsDesktopWidth;
        height: $checkoutStepsDesktopWidth;
      }
    }

    &::after {
      content: '';
      position: absolute;
      width: $checkoutStepsLineMobileWidth;
      height: 2px;
      background: $checkoutStepsColor;
      z-index: 0;
      transform: $checkoutStepsMobileTransform;

      @media (min-width: $screen-tablet-portrait) {
        width: $checkoutStepsLineDesktopWidth;
        transform: $checkoutStepsDesktoptransform;
      }
    }

    &:first-child::after {
      display: none;
    }

    &:focus-visible {
      outline-offset: 8px;
    }

    &--done {
      opacity: 1;
      cursor: pointer;

      &::before {
        background: $checkoutStepsActiveColor;
      }

      &::after {
        background: $checkoutStepsActiveColor;
      }

      #{$root}__step-label {
        color: $checkoutStepsActiveColor;
      }
    }

    &--completed {
      cursor: pointer;
    }

    &--active {
      opacity: 1;

      &::before {
        background: $checkoutStepsActiveColor;
        color: $checkoutStepsTextColor;
      }

      &::after {
        background: $checkoutStepsActiveColor;
      }
    }
  }

  &__step-label {
    white-space: nowrap;
    color: $checkoutStepsColor;
    display: block;
    padding-bottom: 4px;
    margin-top: 4px;
    font-size: $checkoutStepsTextLabelFontSizeMobile;
    font-weight: $checkoutStepsTextLabelFontWeight;

    @media (min-width: $screen-tablet-portrait) {
      margin-top: 8px;
      font-size: $checkoutStepsTextLabelFontSizeDesktop;
    }

    #{$root}__step--active & {
      opacity: 1;
      color: $checkoutStepsActiveColor;
      border-color: currentColor;
    }
  }
}
