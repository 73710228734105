.creditcard {
  &__infotext {
    display: $creditcardInfotextDisplay;
    text-align: left;
    font-size: $fontSizeSmall;
    padding: 10px 0 0;

    @media (min-width: $screen-mobile-landscape) {
      padding: 15px 0 0;
    }
  }

  &__cvc-field {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    & > .input {
      flex-grow: 1;
    }
  }

  &__expire-inputs {
    min-width: 180px;

    .form__col-5,
    .form__col-7 {
      @media (max-width: $screen-mobile-landscape-max) {
        width: 50%;
      }
    }
  }

  &__error-output {
    padding: 0;
    margin: 0;
    display: none;

    &--visible {
      display: block;
    }
  }

  .info-popup__wrapper {
    margin-left: 8px;
    top: 14px;
  }

  .input__error {
    margin: 0 0 10px;
  }

  &__buttons {
    display: flex;
    justify-content: center;
  }

  &__submit-button {
    align-self: flex-end;
    margin: 0 0 11px;
  }

  &__col-no-padding-bottom {
    padding-bottom: 0;
  }
}
