.cart-page {
  $root: &;

  &__container {
    @include checkoutLayout();
  }

  &--list-background {
    background-color: $checkoutPageBackground;
  }

  .general-message {
    padding: 8px 20px;
    margin-bottom: 8px;

    &--type_error {
      background: $basketRedColor;
    }

    &--upselling {
      background: #e9e9e9;

      .general-message__message {
        color: $textBaseColor;
      }

      strong {
        color: $errorColor;
      }
    }
  }

  &__header {
    @include container($maxWidthInner);
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    padding-right: 10px;
    margin: $cartPageHeaderMarginMobile;

    @media (min-width: $screen-tablet-portrait) {
      @include container();
      margin: $cartPageHeaderMarginTablet;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
    }

    @media (min-width: $screen-desktop-sm) {
      margin: $cartPageHeaderMarginDesktop;
    }

    > .button {
      margin: 0 0 15px;
      vertical-align: bottom;
      width: 100%;
      max-width: none;

      @media (min-width: $screen-tablet-portrait) {
        margin: 0;
        width: auto;
      }

      &:last-child {
        margin: 0;
      }
    }
  }

  &__headline-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &__header-text {
    display: flex;
    flex-direction: column;
    margin: 0 0 16px;
    width: 100%;
    text-align: center;

    @media (min-width: $screen-tablet-portrait) {
      margin: 16px auto;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
    }

    &--delivery-promise {
      color: $whiteColor;
      background: $basketGreenColor;
      font-size: 16px;
      line-height: 1.4;
      font-weight: $fontWeightRegular;
      padding: 6px 8px;
      width: 100%;
      text-align: center;
      border-radius: 2px;
    }
  }

  &__headline {
    margin: 0 0 16px;
    width: 100%;
    text-align: center;
    font-size: 25px;

    @media (min-width: $screen-tablet-portrait) {
      font-size: 32px;
    }
  }

  &__headline--with-benefits {
    flex-flow: row wrap;
    align-items: center;
    margin: 0;

    @media (min-width: $screen-tablet-portrait) {
      margin: 0;
    }

    #{$root}__headline {
      text-align: left;
      width: 100%;

      @media (min-width: $screen-tablet-portrait) {
        margin: 0 30px 0 0;
        order: 1;
        width: auto;
      }
    }

    .basket-informative-benefits {
      align-self: center;
      width: auto;

      @media (min-width: $screen-tablet-portrait) {
        order: 2;
        max-width: 65%;
      }
    }
  }

  &__message {
    > div:last-child {
      margin-bottom: 20px;
    }

    > div:first-child {
      &:is(.cart-page__general-message) {
        margin-bottom: 0;
      }
    }
  }

  &__form {
    margin: 0 0 20px;

    @media (min-width: $screen-tablet-portrait) {
      margin: 0 0 40px;
    }
  }

  &__list {
    .products-order-table__placeholder {
      @include componentPreloader() {
        height: auto;
      }
    }
  }

  &__content {
    display: flex;

    @media (min-width: $screen-tablet-landscape) {
      display: grid; // stylelint-disable-line
      // stylelint-disable-next-line
      grid-template-areas:
        "main sidebar"
        "main sidebar"
        "content content";
      grid-template-columns: calc(64% - 10px) calc(36% - 10px);
    }
  }

  &__left {
    grid-area: main; // stylelint-disable-line
    width: 100%;
  }

  &__right {
    grid-area: sidebar; // stylelint-disable-line
    width: 100%;
  }

  &__bottom {
    grid-area: content; // stylelint-disable-line
    width: 100%;
  }

  &__summary-wrapper {
    position: relative;
  }

  &__summary {
    width: 100%;
    padding: 10px 10px 20px;
    border-radius: 4px;
    background: $whiteColor;
    display: flex;
    flex-direction: column;

    @media (min-width: $screen-tablet-landscape) {
      position: sticky;
      top: 150px;
    }

    @media (min-width: $screen-desktop-sm) {
      top: 90px;
    }

    #{$root}--loading & {
      @include componentPreloader() {
        height: auto;
      }
    }
  }

  &--sticky {
    position: fixed;
    top: 0;
    z-index: 1;
  }

  &__anker-order-summary {
    position: absolute;
    margin: -80px 0 0;
  }

  &--show-mobile {
    display: block;

    @media (min-width: $screen-tablet-portrait) {
      display: none !important;
    }
  }

  &__footer-button {
    order: 3;
    width: 100%;

    &__wrapper {
      background-color: transparent;
    }

    &__container {
      @include container($maxWidthInner);
      margin: 24px auto 38px;

      @media (min-width: $screen-tablet-portrait) {
        @include container();
        margin: 24px auto 38px;
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        width: 100%;
        flex-flow: column wrap;
      }
    }
  }

  &__footer-button-col {
    width: 100%;
    order: 1;
    margin-top: 20px;

    @media (min-width: $screen-tablet-portrait) {
      width: auto;
      order: 2;
      margin-top: 0;
      align-self: flex-end;
    }

    > .button {
      margin: 0 0 15px;
      vertical-align: bottom;
      width: 100%;
      max-width: none;

      @media (min-width: $screen-tablet-portrait) {
        margin: 0 0 0 15px;
        width: auto;
        float: right;
      }

      &:last-child {
        margin: 0;
      }
    }
  }

  &__order-summary {
    width: 100%;
    margin: 0;
    padding: 0 0 8px;

    @media (min-width: $screen-tablet-portrait) {
      float: left;
    }

    .checkout-step-delivery & {
      border-top: 0 none;
      border-bottom: 0 none;
      padding: 5px 0 0;

      @media (min-width: $screen-desktop-sm) {
        padding: 24px 0 0;
      }
    }
  }

  &__order-voucher-section {
    display: flex;
    width: 100%;
  }

  &__voucher {
    width: 100%;
  }

  &__info-teaser {
    order: 2;
    width: 100%;

    @media (min-width: $screen-tablet-portrait) {
      order: 1;
      width: 50%;
    }
  }

  &__paypal-text {
    padding: 10px;
    text-align: center;
  }

  &__grid {
    margin: 20px auto 0;
  }

  &__submit-button {
    min-width: 250px;
    width: 100%;
    min-height: 48px;
    margin-bottom: 8px;

    i {
      display: inline-block;
      vertical-align: middle;
      margin: 0 10px 5px 0;
    }
  }

  &__back-to-home-button {
    margin-bottom: 20px;
  }

  &__empty {
    @include fontSize($fontSizeXXMedium);
    color: $cartPageEmptyTextColor;
    font-weight: $cartPageEmptyFontWeight;
    margin: 14px 0 24px;
  }

  &__footer-cross-selling {
    background-color: transparent;
    padding: 0;
  }

  &__empty-basket-container {
    display: none;
  }

  &__empty-content {
    display: none;
    background: $whiteColor;
    border-radius: 4px;
    padding: 8px 20px;
    margin-bottom: 8px;

    &--visible {
      display: block;
    }
  }

  &__buttons {
    .form-button {
      width: 100%;
      max-width: none;
    }

    .button--paypal-checkout {
      background: $basketPaypalColor;
      border: 1px solid $basketPaypalColor;
      height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      max-width: none;

      &:hover {
        background-color: $basketPaypalHoverColor !important;
        border-color: $basketPaypalHoverColor !important;
        box-shadow: none;
      }

      .btn--paypal {
        background-image: url('../staticImages/btn-paypal.svg');
        background-size: 100% 100%;
        height: 20px;
        width: 60px;
      }

      .btn--text {
        display: inline-block;
        padding: 5px 7px 0;
        font-size: 10px;
        line-height: 1.2;
        color: $textBaseColor;
        letter-spacing: 0;
        text-transform: none;

        @media (min-width: $screen-tablet-portrait) {
          font-size: 12px;
        }
      }
    }

    &-bottom {
      display: flex;
      flex-direction: row;
      gap: 8px;

      @media (min-width: $screen-tablet-landscape) {
        flex-direction: column;
      }
    }
  }

  &--empty {
    #{$root}__footer__wrapper { // stylelint-disable-line selector-class-pattern
      margin-bottom: 30px;

      @media (min-width: $screen-tablet-portrait) {
        margin-bottom: 40px;
      }
    }

    #{$root}__empty-basket-container,
    #{$root}__empty-content {
      display: block;
    }

    // stylelint-disable
    .button--paypal-checkout,
    .button--apple-pay-checkout,
    .cart-page__submit-button,
    .cart-page__footer-button__container,
    .cart-page__order-voucher-section,
    .order-summary,
    .basket-informative-benefits,
    .products-order-table__wrapper,
    .favorite-wine-teaser {
      display: none;
    }

    #{$root}__right {
      display: none;
    }
  }

  .sticky-sidebar {
    --sticky-top: 150px;

    @media (min-width: $screen-desktop-sm) {
      --sticky-top: 90px; // these are haw values of --sticky-top, depending on the header height
    }

    &--fixed {
      @media (max-width: $screen-tablet-portrait-max) {
        .greeting-card-form,
        .order-summary__row--shipping,
        .order-summary__row--promotion,
        .order-summary__row--extra-promotion,
        .order-summary__row--discount,
        .order-summary__row--top,
        .order-summary__row--sub-total,
        #{$root}__milesandmore-section,
        #{$root}__cross-selling,
        #{$root}__milesandmore-section,
        #{$root}__order-voucher-section {
          display: none;
        }

        .order-summary__row--total {
          margin-top: 0;
          border-top: 0 none;
          padding-top: 0;
        }
      }
    }
  }
}
