.greeting-card-form {
  margin: 0 0 10px;
  width: 100%;
  font-size: 14px;
  line-height: 1.4;

  @media (min-width: $screen-mobile-landscape-max) {
    max-width: 472px;
  }

  &__checkbox {
    display: inline-block;
    padding: 0 0 6px;
    color: $giftGreetingTextCheckboxColor;

    .checkbox {
      &__input:checked + .checkbox__input::before {
        color: $giftGreetingTextCheckboxCheckedColor;
      }

      &__label {
        font-size: 16px;
      }
    }
  }

  &__container {
    width: 100%;
    margin: 10px 0 20px;
    border: 1px solid $grayColor;
    background: $backgroundWhiteColor;
    padding: 16px 20px;
  }

  &__textarea .input__wrapper {
    min-height: 118px;
    width: 100%;
    padding: 8px;
    font-size: 12px;
    border: $giftGreetingTextBorder !important;

    @media (min-width: $screen-tablet-portrait) {
      font-size: 14px;
      padding: 8px 8px 14px 14px;
    }

    .input__field {
      font-size: 14px;
      line-height: 1.4;
      font-weight: $fontWeightRegular;
      padding: 0;

      &::placeholder {
        color: $grayColor;
        opacity: 1;
        font-size: 14px;
      }
    }

    &--valid::after {
      display: none;
    }
  }

  &__textarea-wrapper {
    width: 100%;
    padding: 12px 0 16px;

    @media (min-width: $screen-tablet-portrait) {
      padding: 7px 0 0;
    }
  }

  &__counter {
    text-align: right;
    font-size: 10px;
    line-height: 1.4;
  }

  &__card-template {
    border-bottom: 1px solid $borderBlackColor;
  }

  &__card-template-subline {
    margin: 0 0 5px;
    font-size: 14px;
  }

  &__picker {
    display: flex;
    flex-wrap: wrap;

    .input__error {
      margin: 0 0 20px;
      width: 100%;
    }
  }

  &__picker-item {
    width: 50%;
    display: flex;
    padding: 0 20px 0 0;
    cursor: pointer;
  }

  &__picker-image {
    width: auto;
    margin: 0 0 0 10px;

    img {
      width: 100%;
    }
  }

  &__text-selection {
    margin: 20px 0 0;
    display: flex;
    flex-wrap: wrap;
  }

  &__text-selection-option {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;

    &:not(:first-child) {
      margin: 30px 0 10px;
    }
  }

  &__text-selection-text {
    margin: 10px 0 0 29px;
    width: 100%;
    font-weight: $fontWeightRegular;
    font-size: 14px;
  }

  &__button--submit {
    margin: 10px 0 0 auto;
    width: 100%;
  }

  &__info {
    margin: 20px 0 0;
    font-size: 14px;
  }

  &__text-selection-headline {
    font-size: 16px;
    margin: 0 0 0 10px;
    font-weight: $fontWeightMedium;
  }

  &__text-selection-subline--standard {
    font-size: 16px;
    margin: 0 10px;
  }

  &__text-selection-subline--custom {
    font-size: 14px;
    margin: 0 0 0 29px;
    width: 100%;
  }

  &__text-selection-headline--custom {
    font-size: 16px;
    margin: 0 10px;
    font-weight: $fontWeightMedium;
  }

  &__headline-wrapper {
    display: flex;
    align-items: center;
  }

  &__card-template-headline {
    font-size: 16px;
    margin: 0 5px 0 0;
    font-weight: $fontWeightMedium;
  }

  .info-popup__wrapper {
    margin: 0 0 0 5px;
  }

  .checkbox--switch .info-popup__wrapper {
    margin: -1px 0 0 -4px;
    order: 3;
  }

  &__text-selection-radio--custom {
    .info-popup__wrapper {
      display: flex;
    }
  }
}
