.register-address-form {
  &__option {
    background-position: right center;
    background-repeat: no-repeat;
    background-size: contain;

    &--hermes_paketshop {
      background-image: url(../staticImages/delivery-type-hermes.png);
    }
  }

  &__billing-address {
    &--collapsed {
      .input--disabled,
      .select--disabled,
      .radio-input--inline{
        display: none;
      }
    }
  }

  .radio-input {
    align-items: center;

    &__label {
      font-size: $fontSizeBase;
      font-weight: $fontWeightMedium;
    }
  }

  .form__option-area {
    background: $registerAddressFormOptionAreaBackground;
  }

  .address-form__wrapper {
    .form__option-area {
      &:has(input:checked) { // stylelint-disable-line
        border-color: transparent;
        box-shadow: none;
      }
    }

    .address-form__radios-wrapper {
      border-radius: $addressFormRadiosBorderRadius;
      margin: $addressFormRadiosMargin;

      &--checked {
        border: $addressFormRadiosBorderChecked;
        box-shadow: $addressFormRadiosBorderBoxShadow;
        background: $addressFormRadiosBackground;

        .address-form-inputs {
          padding: $addressFormRadiosFormPadding;
        }
      }
    }

    .address-form__radios {
      margin: 0;
    }

    .address-form__inputs {
      margin: 0;
      padding: 0;
    }

    .hermes-widget {
      padding: 5px 22px 0;
    }
  }
}
