.favorite-wine-teaser {
  position: relative;
  background: $favoriteWineBackground;
  border-radius: 4px;
  margin-top: 24px;
  display: flex;

  @media (min-width: $screen-tablet-landscape) {
    justify-content: space-between;
  }

  &__head {
    @include fontSize($fontSizeXXMedium);
    font-weight: $fontWeightBold;
    color: $brandSecondaryColor;
  }

  &__text {
    @include fontSize($fontSizeSmall);
    margin: 0;
    font-weight: $fontWeightMedium;
    line-height: 1.2;
  }

  &__select {
    margin: 0;
    width: 100%;
  }

  &__disclaimer {
    font-size: $fontSizeXXSmall;
  }

  &__form {
    width: 100%;
    padding: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 12px;

    @media (min-width: $screen-tablet-portrait) {
      width: 60%;
      padding: 16px;
    }
  }

  &__img {
    height: 100%;
    max-height: 140px;
    max-width: 100%;
  }

  &__img-container {
    display: none;

    @media (min-width: $screen-tablet-portrait) {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 16px 0;
      width: 40%;
    }
  }

  .select__custom-option {
    color: $selectTextColor;

    &:hover {
      color: $selectCustomOptionHoverColor;
    }
  }

  .favorite-wine-teaser-select--disabled {
    .select {
      &__wrapper {
        background: $whiteColor;
      }

      &__label {
        color: $redColor;
      }
    }
  }
}
