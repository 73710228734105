.radio-input-submit {
  flex-wrap: wrap;
  cursor: auto;

  .radio-input__input {
    display: none;
  }

  .radio-input__label {
    padding: 0 0 20px;
  }
}
