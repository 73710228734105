.shipping-address-form {
  margin: 0 0 24px;

  &__address-option-label {
    margin-left: 12px;
    width: 100%;
  }

  &__address-option-head {
    margin-bottom: 12px;
    font-weight: $fontWeightSemiBold;
  }

  &__button {
    width: 100%;
    margin-top: 16px;

    &.button--cancel {
      margin-top: 8px;
      text-align: center !important;
    }
  }

  &__new {
    .form__option-area {
      &:has(input:checked) { // stylelint-disable-line
        border-color: transparent;
        box-shadow: none;
      }
    }

    .address-form__radios-wrapper {
      border-radius: $addressFormRadiosBorderRadius;
      margin: $addressFormRadiosMargin;

      &--checked {
        border: $addressFormRadiosBorderChecked;
        box-shadow: $addressFormRadiosBorderBoxShadow;
        background: $addressFormRadiosBackground;

        .address-form-inputs {
          padding: $addressFormRadiosFormPadding;
        }
      }
    }

    .address-form__radios {
      margin: 0;
    }

    .address-form__inputs {
      margin: 0;
      padding: 0;
    }

    .hermes-widget {
      padding: 5px 16px 10px;
    }
  }

  .radio-input:has(input:disabled) .address-box { // stylelint-disable-line plugin/no-unsupported-browser-features
    color: $grayColor;
  }
}
