.checkout-element {
  &__headline {
    @include container();
    margin-bottom: 32px;
    font-size: $fontSizeLarge;
    text-align: center;
    font-weight: $fontWeightRegular;

    em, strong {
      font-weight: $fontWeightBold;
      font-style: normal;
    }
  }

  &__section {
    & + & {
      margin-top: 24px;
    }
  }

  &__section-title {
    margin: 0 0 12px;
    font-size: $fontSizeMedium;
    font-weight: $fontWeightBold;
  }

  &__box {
    background: $checkoutElementBoxBackground;
    border-radius: 4px;
    padding: 12px;
    position: relative;

    @media (min-width: $screen-mobile-landscape) {
      padding: 16px 20px;
    }

    .checkout-page__main & {
      background: $checkoutElementBoxLeftSideBackground;
    }

    &--error {
      border: $errorColor 1px solid;
    }
  }

  &__box-action {
    position: absolute;
    top: 12px;
    right: 12px;
    border: none;
    background: transparent;
    cursor: pointer;
    z-index: 1;
    font-size: $fontSizeSmall;
    padding: 16px;
    transform: translate(16px, -16px);
    text-decoration: underline;
    color: $textBaseColor;

    @media (min-width: $screen-mobile-landscape) {
      top: 16px;
      right: 20px;
    }

    span {
      display: block;
      color: $textBaseColor;
    }
  }
}
